import React from "react";

import EditTuitionAndFeesView, { processServerErrors } from "./view";
import { EditFacilityDialog } from "@pages/DeepDivePanel/EditFacilityDialog";
import { useDeepDivePanelStore } from "@hooks/useDeepDivePanelStore";

const EditTuitionAndFees: React.FC = () => {
  const [{ facility }] = useDeepDivePanelStore();

  return (
    <EditFacilityDialog
      name="EditTuitionAndFees"
      title="Tuition & fees"
      viewPath={`/map/${facility.id}/tuition`}
      processErrors={processServerErrors}
    >
      {(view, setView) => (
        <EditTuitionAndFeesView facilityView={view} setFacilityView={setView} />
      )}
    </EditFacilityDialog>
  );
};

export default EditTuitionAndFees;
